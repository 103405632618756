import React, { useRef, useState, useEffect } from 'react';
import { QRCode } from 'lifetaggerQrCode';
import { useTheme } from '@mui/material/styles';
import { Button, Divider, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Remove from './beacon.remove';
import { Modal } from '@material-ui/core';

function ShowQrCode(props) {
  const { qrCode, direction, size, justifyContent, deleteButton, history } =
    props;
  const theme = useTheme();
  const ref = useRef();
  const gridSize = qrCode.type == 'beacon' ? 12 : 6;
  let [open, setOpen] = useState(false);
  const [url, setUrl] = useState(
    `${process.env.REACT_APP_URL}/qrtags/${qrCode.beaconId}`
  );

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `https://tinyurl.com/api-create.php?url=${process.env.REACT_APP_URL}/qrtags/${qrCode.beaconId}`
        );
        const shortenedUrl = await response.text();
        setUrl(shortenedUrl);
      } catch (e) {
        console.log('error');
      }
    }
    fetchData();
  }, [props.qrCode]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setOpen(false);
    history.push(qrCode.type == 'beacon' ? '/beacons' : '/qrcode');
  };

  return (
    <div>
      <Modal
        open={open}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Remove
          beacon={qrCode}
          handleClose={handleDelete}
          remove={props.remove}
        />
      </Modal>
      <Grid
        container
        direction={direction}
        justifyContent={justifyContent}
        sx={theme.custom.lifeCodeDivBox}
        spacing={1}
      >
        {qrCode.type != 'beacon' && (
          <Grid item xs={5.5} sx={theme.custom.lifeCodeDivBox} ref={ref}>
            <QRCode
              value={url}
              ecLevel={'Q'}
              logoImage={qrCode.image || '/images/Layer_2@3x.png'}
              fgColor={qrCode.fgColor || '#262626'}
              logoWidth="35"
              bgColor={qrCode.bgColor || '#f2f2f2'}
              size={size}
              qrStyle={qrCode.qrStyle || 'dots'}
            />
          </Grid>
        )}
        <Grid item xs={gridSize}>
          <Grid
            container
            direction={'column'}
            justifyContent={'flex-end'}
            flexDirection={'column'}
            spacing={2}
          >
            <Grid item xs={12}>
              <div style={theme.custom.typography.subTitleV2}>
                {qrCode.name}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={theme.custom.typography.descriptionTitle}>
                {qrCode.description}
              </div>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid container direction={'column'} spacing={2}>
              {qrCode.type == 'beacon' && (
                <Grid
                  item
                  xs={12}
                  alignSelf={'center'}
                  sx={{ marginTop: '5px' }}
                >
                  <div style={theme.custom.typography.descriptionTitle}>
                    {qrCode.beaconId}
                  </div>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                alignSelf={'flex-end'}
                justifySelf={'flex-end'}
              >
                {!deleteButton ? (
                  <Button
                    size={'small'}
                    startIcon={<EditIcon />}
                    sx={theme.custom.typography.subTitleV2}
                  >
                    <div style={theme.custom.typography.subTitleV2}>Edit</div>
                  </Button>
                ) : (
                  <Button
                    size={'small'}
                    startIcon={<DeleteForeverIcon />}
                    onClick={handleOpen}
                    sx={theme.custom.typography.subTitleV2}
                  >
                    <div style={theme.custom.typography.subTitleV2}>Delete</div>
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default ShowQrCode;
