import React, { Component } from 'react';
import { Button, Modal, Grid } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { CreateQr as CreateQrCode } from './beacon.container';
import { ShowQr } from './beacon.container';
import { createMuiTheme } from '@mui/material';

let theme = createMuiTheme();
const styles = {
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  margin: {
    margin: theme.spacing.unit * 2,
  },

  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`,
  },

  card: {
    margin: '10px auto',
    width: '95%',
  },
  delete: {
    background: '#c22d08',
    border: 0,
    color: 'white',
    padding: '10px',
    boxShadow: '0 3px 5px 2px rgba(235, 64, 52 .3)',
    borderRadius: 5,
  },
  modal: {
    display: 'flex',
    borderRadius: 40,
    padding: '10px',
    backgroundColor: 'white',
  },
  typography: {
    fontFamily: ['Helvetica'].join(','),
    fontSize: 14,
    body1: {
      fontWeight: 400,
      fontFamily: 'Helvetica',
    },
  },
  datetext: {
    fontFamily: ['Helvetica'].join(','),
    fontSize: 12,
    body1: {
      fontWeight: 400,
      fontFamily: 'Helvetica',
    },
  },
  tableHeading: {
    fontFamily: ['Helvetica'].join(','),
    fontSize: 16,
    color: 'black',
    body1: {
      fontWeight: 500,
      fontFamily: 'Helvetica',
    },
  },

  myButton: {
    backgroundColor: '#A80000',
    color: '#FFFFFF',
    borderRadius: 29.5,
    fontWeight: 700,
    fontSize: 14,
    fontFamily: 'helvetica',
    marginLeft: '22px',
    marginBottom: 18,
  },

  button: {
    '&:hover': {
      background: 'transparent',
    },
  },
};

class QrIndex extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.props.allLifeCodes();
  }

  componentDidMount() {}

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  createQr() {
    this.setState({ open: true });
  }

  render() {
    const { classes, history } = this.props;

    return (
      <Grid container direction="column">
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            // className={classes.myButton}
            onClick={() => {
              this.createQr();
            }}
          >
            Create LifeCode
          </Button>
        </Grid>
        <MuiThemeProvider>
          <Modal
            open={this.state.open}
            className={classes.Modal}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'scroll',
            }}
            onClose={this.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <CreateQrCode handleClose={this.handleClose} />
          </Modal>
        </MuiThemeProvider>

        {this.props.beacons.length > 0 && (
          <Grid container spacing={3}>
            {this.props.beacons.map(
              (item, key) =>
                item.type === 'qrcode' && (
                  <Grid
                    item
                    xs={4}
                    key={key}
                    onClick={() =>
                      history.push('/qrcode/details/' + item.beaconId)
                    }
                  >
                    <ShowQr
                      qrCode={item}
                      size={122}
                      direction="row"
                      justifyContent="flex-start"
                      key={key}
                    />
                  </Grid>
                )
            )}
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(QrIndex);
