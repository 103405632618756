import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QRCode } from 'lifetaggerQrCode';
import uuid from 'react-uuid';
import { makeStyles } from '@material-ui/core/styles';
import Parse from 'parse';
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  Typography,
  Card,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { SketchPicker } from 'react-color';
import Upload from '../ui/upload.component';
import { AddCircleOutline } from '@mui/icons-material';
import { All as UCIndex } from '../userconnectors/userconnector.container';
import { All as ConnectorIndex } from '../connectors/connector.container';
import { fetchConnectorAction } from '../connectors/connector.actions';

const useStyles = makeStyles({
  card: {
    margin: '10px auto',
    padding: '20px',
    width: '95%',
  },
});

const mySavedParams = {};

const CreateQrCode = (props) => {
  const classes = useStyles();
  const [beaconId] = useState(uuid().toUpperCase());
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [type] = useState('qrcode');
  const [image, setImage] = useState('/images/Layer_2@3x.png');
  const [fgColor, setFgColor] = useState('#262626');
  const [bgColor, setBgColor] = useState('#f2f2f2');
  const [qrStyle, setQrStyle] = useState('dots');
  const [shortenedUrl, setShortenedUrl] = useState('');
  const theme = useTheme();
  const [followButtonText, setFollowButtonText] = useState('Follow');
  const [followButtonFields, setFollowButtonFields] = useState([]);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [activeConnector, setActiveConnector] = useState(null);
  const [activeStep, setActiveStep] = useState('connect');
  const connectors = useSelector((state) => state.connector?.data);
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [dataBases, setDatabases] = useState([]);
  const [dataBase, setDataBase] = useState(null);

  const [currentStepParams, setCurrentStepParams] = useState([]);
  //const [savedParams, setSavedParams] = useState({});

  useEffect(() => {
    const fetchUrl = async () => {
      const response = await fetch(
        `https://tinyurl.com/api-create.php?url=${process.env.REACT_APP_URL}/qrtags/${beaconId}`
      );
      const url = await response.text();
      setShortenedUrl(url);
    };

    fetchUrl();
  }, [beaconId]);

  const handleFileUpload = async (files) => {
    let parseImage = new Parse.File('image', files[0], files[0].type);
    await parseImage.save();
    setImage(parseImage.url());
  };

  const handleDatabaseChange = (event) => {
    setDataBase(event.target.value);
  };

  const removeField = (id) => {
    // remove field on followButtonFields with the sent in id
    const newFollowButtonFields = removeObjectById(followButtonFields, id);
    setFollowButtonFields(newFollowButtonFields);
  };

  function removeObjectById(array, idToRemove) {
    return array.filter((obj) => obj.id !== idToRemove);
  }

  const handleTableChange = (event) => {
    setSelectedTable(event.target.value);
  };

  useEffect(() => {
    if (dataBase) {
      const getNextAction = activeConnector.actions?.find(
        (action) => action.step === activeStep
      );
      //const newSavedParams = Object.assign({}, savedParams);

      for (let i = 0; i < currentStepParams.length; i++) {
        const textReplace = dataBase[currentStepParams[i]];
        if (textReplace) mySavedParams[currentStepParams[i]] = textReplace;
        const replaceWith = currentStepParams[i];
        const regex = new RegExp(`{(${replaceWith})}`, 'g');
        getNextAction.path = getNextAction.path.replace(regex, textReplace);
      }
      //setSavedParams(newSavedParams);

      if (getNextAction.next) {
        if (getNextAction.next.params) {
          setCurrentStepParams(getNextAction.next.params);
        }
        setActiveStep(getNextAction.next.step);
      }
      getNextAction.params = { id: getNextAction.step };

      dispatch(fetchConnectorAction(getNextAction));
    }
  }, [dataBase]);

  useEffect(() => {
    if (selectedTable) {
      const getNextAction = activeConnector.actions?.find(
        (action) => action.step === activeStep
      );

      //const newSavedParams = Object.assign({}, savedParams);

      for (let i = 0; i < currentStepParams.length; i++) {
        let textReplace = selectedTable[currentStepParams[i]];

        if (textReplace === undefined) {
          textReplace = mySavedParams[currentStepParams[i]];
        }

        if (textReplace) mySavedParams[currentStepParams[i]] = textReplace;
        const replaceWith = currentStepParams[i];
        const regex = new RegExp(`{(${replaceWith})}`, 'g');
        getNextAction.path = getNextAction.path.replace(regex, textReplace);
      }
      //setSavedParams(newSavedParams);

      // for loop to replace getNextAction.path which has strings that should be replaced when they have this form {someString} find the

      if (getNextAction.next) {
        if (getNextAction.next.params) {
          setCurrentStepParams(getNextAction.next.params);
        }
        setActiveStep(getNextAction.next.step);
      }

      if (selectedTable.fields) {
        handleFields(selectedTable);
      } else {
        dispatch(fetchConnectorAction(getNextAction));
      }
    }
  }, [selectedTable]);

  // useEffect(() => {
  //   const getNextAction = activeConnector.actions?.find(
  //     (action) => action.step === activeStep
  //   );

  //   if (getNextAction) {
  //     dispatch(fetchConnectorAction(getNextAction));
  //     if (getNextAction.next) {
  //       console.log(getNextAction.next.step);
  //       if (getNextAction.next.params) {
  //         setCurrentStepParams(getNextAction.next.params);
  //       }
  //       setActiveStep(getNextAction.next.step);
  //     }
  //   }
  // }, [tables]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const connector = connectors?.find((b) => b.id === activeConnector?.id);
    if (connector) {
      setActiveConnector(connector);
    }

    if (connector?.actionData) {
      if (activeStep === 'connectTable') {
        setDatabases(connector.actionData);
      }
      if (activeStep === 'connectFields') {
        setTables(connector.actionData);
      }
    }
  }, [connectors]);

  const handleConnectorActionClick = (action, id) => {
    const connector = connectors.find((b) => b.id === id);

    setActiveConnector(connector);
    if (action.next) {
      if (action.next.params) {
        setCurrentStepParams(action.next.params);
      }
      setActiveStep(action.next.step);
    }

    dispatch(fetchConnectorAction(action));
  };

  const handleFields = (table) => {
    const newFollowButtonFields = JSON.parse(
      JSON.stringify(followButtonFields)
    );

    const getActionButtonStep = activeConnector.actions?.find(
      (action) => action.step === 'connectButton'
    );

    for (let i = 0; i < getActionButtonStep.params?.length; i++) {
      let textReplace = mySavedParams[getActionButtonStep.params[i]];
      const replaceWith = currentStepParams[i];
      const regex = new RegExp(`{(${replaceWith})}`, 'g');
      getActionButtonStep.path = getActionButtonStep.path.replace(
        regex,
        textReplace
      );
    }

    for (let i = 0; i < table.fields.length; i++) {
      let field = table.fields[i];
      newFollowButtonFields.push({
        id: field.id,
        label: field.name,
        name: field.name,
        tableId: table.id,
        action: getActionButtonStep.path || null,
        method: getActionButtonStep.method || null,
      });
    }

    setFollowButtonFields(removeDuplicates(newFollowButtonFields));
  };

  function removeDuplicates(array) {
    const seenIds = new Set();
    return array.filter((obj) => {
      if (seenIds.has(obj.id)) {
        return false; // Skip objects with duplicate IDs
      } else {
        seenIds.add(obj.id);
        return true;
      }
    });
  }

  const saveBeacon = () => {
    props.create({
      beaconId,
      name,
      description,
      type,
      image,
      fgColor,
      bgColor,
      qrStyle,
      shortenedUrl,
      followButtonFields,
      followButtonText,
    });
    setName('');
    setDescription('');
    props.handleClose();
  };

  return (
    <Card className={classes.card}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={6}>
            <Typography sx={theme.custom.typography.header}>
              Create a LifeCode
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            alignSelf={'end'}
            justifyContent={'end'}
            alignItems={'end'}
            container
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              endIcon={<ArrowRightAltIcon />}
              onClick={saveBeacon}
            >
              Create LifeCode
            </Button>
          </Grid>
        </Grid>
        <Grid container direction={'row'} spacing={2} item xs={12}>
          <Grid container item xs={12} sm={12} md={6} lg={3} spacing={3}>
            <Grid item xs={12}>
              <Typography sx={{ marginBottom: '15px' }} variant="h4">
                Describe
              </Typography>
              <Grid item xs={12}>
                <TextField
                  id="name"
                  value={name}
                  label="Name"
                  variant="filled"
                  margin="normal"
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '15px' }}>
                <TextField
                  id="description"
                  value={description}
                  margin="normal"
                  variant="filled"
                  label="Description"
                  onChange={(e) => setDescription(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">Style</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Add Your Logo</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="imageUrl"
                  margin="normal"
                  variant="filled"
                  label="Image URL"
                  onChange={(e) => setImage(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>or Upload an image</Typography>
              </Grid>
              <Grid item xs={12}>
                <Upload
                  handleFileUpload={handleFileUpload}
                  mediaType={[
                    'image/png',
                    'image/jpeg',
                    'image/jpg',
                    'image/gif',
                    'image/bmp',
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="qrStyle"
                    name="qrStyle1"
                    value={qrStyle}
                    onChange={(e) => setQrStyle(e.target.value)}
                  >
                    <FormControlLabel
                      value="dots"
                      control={<Radio />}
                      label="Dots"
                    />
                    <FormControlLabel
                      value="squares"
                      control={<Radio />}
                      label="Squares"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} item xs={12} sm={12} md={6} lg={3}>
            <Grid item xs={12}>
              <Typography sx={{ marginBottom: '15px' }} variant="h4">
                Style cont...
              </Typography>
              <Grid container direction={'row'} item xs={12}>
                <Grid item xs={6} md={12} sm={6} lg={12}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      Background Color
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <SketchPicker
                      color={fgColor}
                      onChangeComplete={(color) => setBgColor(color.hex)}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      Foreground Color
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <SketchPicker
                      color={fgColor}
                      onChangeComplete={(color) => setFgColor(color.hex)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} item xs={12} sm={12} md={6} lg={3}>
            <Grid item xs={12}>
              <Typography sx={{ marginBottom: '15px' }} variant="h4">
                Configure Connect Button
              </Typography>
              <Grid item xs={12}>
                <TextField
                  id="followButtonText"
                  value={followButtonText}
                  label="Follow Button Text"
                  variant="filled"
                  margin="normal"
                  onChange={(e) => setFollowButtonText(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: '15px' }}>
                <Grid item xs={6}>
                  <Typography variant="h6">Follow Fields</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant={'text'}
                    onClick={() => setOpen(true)}
                    startIcon={<AddCircleOutline />}
                  >
                    Add Field
                  </Button>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      component: 'form',
                      onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        setFollowButtonFields([
                          ...followButtonFields,
                          formJson,
                        ]);

                        handleClose();
                      },
                    }}
                  >
                    <DialogTitle>New Field</DialogTitle>
                    <DialogContent>
                      <TextField
                        required
                        margin="dense"
                        id="id"
                        name="id"
                        label="Enter unique id for this field(eg. email)"
                        fullWidth
                        variant="standard"
                      />
                      <TextField
                        required
                        margin="dense"
                        id="name"
                        name="name"
                        label="Name of Field"
                        fullWidth
                        variant="standard"
                      />
                      <TextField
                        margin="dense"
                        id="label"
                        name="label"
                        label="Description of the field"
                        fullWidth
                        variant="standard"
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button type="submit">Add</Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                {/* <Grid item xs={12}>
                  <TextField
                    id="followFieldName"
                    value="Name"
                    label="Name"
                    variant="filled"
                    disabled
                    margin="normal"
                    fullWidth
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => removeField('followFieldName')}
                  >
                    Remove
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="followFieldEmail"
                    value="Email"
                    disabled
                    label="Email"
                    variant="filled"
                    margin="normal"
                    fullWidth
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => removeField('followFieldEmail')}
                  >
                    Remove
                  </Button>
                </Grid> */}
                {followButtonFields.map((field, index) => (
                  <Grid item xs={12} key={index}>
                    <Grid item xs={8}>
                      <TextField
                        id={field.id}
                        value={field.name}
                        disabled
                        label={field.label}
                        variant="filled"
                        margin="normal"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => removeField(field.id)}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} item xs={12} sm={12} md={6} lg={3}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography sx={{ marginBottom: '15px' }} variant="h4">
                  Your LifeCode
                </Typography>
              </Grid>
              <QRCode
                value={shortenedUrl}
                logoImage={image}
                fgColor={fgColor}
                bgColor={bgColor}
                size="200"
                qrStyle={qrStyle}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12} style={{ marginBottom: '24px' }}>
                <Typography className={classes.title}>
                  Connected Integrations
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {
                  <UCIndex
                    step={'connect'}
                    callBack={handleConnectorActionClick}
                  />
                }
              </Grid>
              <Grid item xs={12} style={{ marginBottom: '24px' }}>
                <Typography className={classes.subtitle}>
                  Connect An Integration
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                {<ConnectorIndex step={'connect'} />}
              </Grid>
              {activeStep === 'connectTable' && (
                <Grid item xs={12}>
                  <Typography className={classes.subtitle}>
                    Connect a Datasource
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Choose
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={dataBase}
                      label="Choose"
                      onChange={handleDatabaseChange}
                    >
                      {dataBases.map((action, index) => {
                        return (
                          <MenuItem key={index} value={action}>
                            {action.dataSource}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {dataBase && (
                <Grid item xs={12}>
                  <Typography className={classes.subtitle}>
                    Connect a table
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Connect a table
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedTable}
                      label="Choose"
                      onChange={handleTableChange}
                    >
                      {tables.map((table, index) => {
                        return (
                          <MenuItem key={index} value={table}>
                            {table.tableName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CreateQrCode;
