import React, { Component } from 'react';
import { Grid, Modal, Box } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Update as Edit } from './tag.container';
import TagPreview from '../ui/tagPreview.component';
import { Button, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

let theme = createMuiTheme();

const styles = {
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  margin: {
    margin: theme.spacing(2),
  },

  padding: {
    padding: `0 ${theme.spacing(2)}px`,
  },

  card: {
    margin: '10px auto',
    width: '95%',
  },
  delete: {
    borderRadius: '13px',

    //backgroundColor: "#A80000",
    color: '#A80000',
    // borderRadius: 29.5,
    fontWeight: 700,
    fontSize: 14,
    fontFamily: 'helvetica',
    marginLeft: '7px',
  },
  edit: {
    background: '#0000FF',
    border: 0,
    color: 'white',
    padding: '10px',
    boxShadow: '0 3px 5px 2px rgba(235, 64, 52 .3)',
    borderRadius: 5,
    marginRight: '10px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
    borderRadius: 20,
    paddingBottom: '10px',
  },
  typography: {
    fontFamily: ['Helvetica'].join(','),
    fontSize: 14,
    body1: {
      fontWeight: 400,
      fontFamily: 'Helvetica',
    },
  },
  datetext: {
    fontFamily: ['Helvetica'].join(','),
    fontSize: 12,
    body1: {
      fontWeight: 400,
      fontFamily: 'Helvetica',
    },
  },
  tableHeading: {
    fontFamily: ['Helvetica'].join(','),
    fontSize: 16,
    color: 'black',
    body1: {
      fontWeight: 500,
      fontFamily: 'Helvetica',
    },
  },
};

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      currentTag: {},
      filteredTags: [],
      searchText: '',
    };

    //this.setChannels = this.setChannels.bind(this);
  }

  componentDidMount() {
    this.props.all();
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  renderEdit(tag) {
    this.setState({ currentTag: tag });
    this.setState({ open: true });
  }

  render() {
    const { classes, history } = this.props;
    const tags =
      this.state.filteredTags.length === 0 && this.state.searchText.length === 0
        ? this.props.tags
        : this.state.filteredTags;

    return (
      <MuiThemeProvider>
        <Box mb={2}>
          <TextField
            label="Search Tags"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              let newFilteredState = this.props.tags.filter(
                (tag) => !tag.message || tag.message.includes(e.target.value)
              );

              newFilteredState =
                newFilteredState.length === 0 && e.target.value.length === 0
                  ? this.props.tags
                  : newFilteredState;

              this.setState({
                filteredTags: newFilteredState,
                searchText: e.target.value,
              });
            }}
          />
        </Box>
        <Grid container>
          <Modal
            className={classes.modal}
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Edit tag={this.state.currentTag} handleClose={this.handleClose} />
          </Modal>
          <Grid container spacing={2}>
            {tags.map((item, key) => (
              <div key={key} style={{ marginRight: '7px' }}>
                <TagPreview
                  tag={item}
                  deleteFunction={this.props.deleteTag}
                  showEditButtons={true}
                />
                <Box
                  px={1}
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <Button
                        aria-label="edit"
                        size="small"
                        sx={{ color: '#0F1F54' }}
                        onClick={() => {
                          history.push('/tag/edit/' + item.objectId);
                          //this.renderEdit(item);
                        }}
                      >
                        <ListIcon />
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        className={classes.delete}
                        aria-label="delete"
                        sx={{ color: '#DD0B06' }}
                        size="small"
                        onClick={() => {
                          this.props.deleteTag(item.objectId);
                        }}
                      >
                        <DeleteForeverIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            ))}
          </Grid>
        </Grid>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(Index);
